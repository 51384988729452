/* @tailwind base; */

.container {
  width: 100%;
}

@media (min-width: 640px) {
  .container {
    max-width: 640px;
  }
}

@media (min-width: 768px) {
  .container {
    max-width: 768px;
  }
}

@media (min-width: 1024px) {
  .container {
    max-width: 1024px;
  }
}

@media (min-width: 1280px) {
  .container {
    max-width: 1280px;
  }
}

@media (min-width: 1536px) {
  .container {
    max-width: 1536px;
  }
}

.sr-only {
  position: absolute !important;
  width: 1px !important;
  height: 1px !important;
  padding: 0 !important;
  margin: -1px !important;
  overflow: hidden !important;
  clip: rect(0, 0, 0, 0) !important;
  white-space: nowrap !important;
  border-width: 0 !important;
}

.fixed {
  position: fixed !important;
}

.absolute {
  position: absolute !important;
}

.relative {
  position: relative !important;
}

.inset-0 {
  inset: 0px !important;
}

.right-0 {
  right: 0px !important;
}

.top-\[0\.5rem\] {
  top: 0.5rem !important;
}

.z-10 {
  z-index: 10 !important;
}

.m-auto {
  margin: auto !important;
}

.mx-auto {
  margin-left: auto !important;
  margin-right: auto !important;
}

.my-4 {
  margin-top: 1rem !important;
  margin-bottom: 1rem !important;
}

.mb-40 {
  margin-bottom: 10rem !important;
}

.mt-1 {
  margin-top: 0.25rem !important;
}

.mt-2 {
  margin-top: 0.5rem !important;
}

.mt-4 {
  margin-top: 1rem !important;
}

.mt-6 {
  margin-top: 1.5rem !important;
}

.block {
  display: block !important;
}

.inline-block {
  display: inline-block !important;
}

.flex {
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
}

.table {
  display: table !important;
}

.hidden {
  display: none !important;
}

.h-12 {
  height: 3rem !important;
}

.h-32 {
  height: 8rem !important;
}

.h-4 {
  height: 1rem !important;
}

.h-48 {
  height: 12rem !important;
}

.h-6 {
  height: 1.5rem !important;
}

.h-8 {
  height: 2rem !important;
}

.h-96 {
  height: 24rem !important;
}

.h-\[1180px\] {
  height: 1180px !important;
}

.h-full {
  height: 100% !important;
}

.h-px {
  height: 1px !important;
}

.min-h-screen {
  min-height: 100vh !important;
}

.w-20 {
  width: 5rem !important;
}

.w-32 {
  width: 8rem !important;
}

.w-4 {
  width: 1rem !important;
}

.w-40 {
  width: 10rem !important;
}

.w-6 {
  width: 1.5rem !important;
}

.w-60 {
  width: 15rem !important;
}

.w-8 {
  width: 2rem !important;
}

.w-80 {
  width: 20rem !important;
}

.w-\[1180px\] {
  width: 1180px !important;
}

.w-\[30px\] {
  width: 30px !important;
}

.w-\[40px\] {
  width: 40px !important;
}

.w-\[45px\] {
  width: 45px !important;
}

.w-\[50px\] {
  width: 50px !important;
}

.w-\[60px\] {
  width: 60px !important;
}

.w-\[80px\] {
  width: 80px !important;
}

.w-\[840px\] {
  width: 840px !important;
}

.w-full {
  width: 100% !important;
}

.max-w-2xl {
  max-width: 42rem !important;
}

.flex-1 {
  -webkit-box-flex: 1 !important;
  -ms-flex: 1 1 0% !important;
  flex: 1 1 0% !important;
}

.flex-\[2_2_0\%\] {
  -webkit-box-flex: 2 !important;
  -ms-flex: 2 2 0% !important;
  flex: 2 2 0% !important;
}

.flex-\[5_5_0\%\] {
  -webkit-box-flex: 5 !important;
  -ms-flex: 5 5 0% !important;
  flex: 5 5 0% !important;
}

.table-fixed {
  table-layout: fixed !important;
}

.transform {
  -webkit-transform: translate(var(--tw-translate-x), var(--tw-translate-y))
    rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y))
    scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y)) !important;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y))
    rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y))
    scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y)) !important;
}

.break-inside-avoid {
  -webkit-column-break-inside: avoid !important;
  break-inside: avoid !important;
}

.break-after-page {
  -webkit-column-break-after: page !important;
  break-after: page !important;
}

.flex-row {
  -webkit-box-orient: horizontal !important;
  -webkit-box-direction: normal !important;
  -ms-flex-direction: row !important;
  flex-direction: row !important;
}

.flex-col {
  -webkit-box-orient: vertical !important;
  -webkit-box-direction: normal !important;
  -ms-flex-direction: column !important;
  flex-direction: column !important;
}

.flex-wrap {
  -ms-flex-wrap: wrap !important;
  flex-wrap: wrap !important;
}

.items-start {
  -webkit-box-align: start !important;
  -ms-flex-align: start !important;
  align-items: flex-start !important;
}

.items-end {
  -webkit-box-align: end !important;
  -ms-flex-align: end !important;
  align-items: flex-end !important;
}

.items-center {
  -webkit-box-align: center !important;
  -ms-flex-align: center !important;
  align-items: center !important;
}

.items-baseline {
  -webkit-box-align: baseline !important;
  -ms-flex-align: baseline !important;
  align-items: baseline !important;
}

.justify-end {
  -webkit-box-pack: end !important;
  -ms-flex-pack: end !important;
  justify-content: flex-end !important;
}

.justify-center {
  -webkit-box-pack: center !important;
  -ms-flex-pack: center !important;
  justify-content: center !important;
}

.justify-between {
  -webkit-box-pack: justify !important;
  -ms-flex-pack: justify !important;
  justify-content: space-between !important;
}

.-space-y-1 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0 !important;
  margin-top: calc(-0.25rem * calc(1 - var(--tw-space-y-reverse))) !important;
  margin-bottom: calc(-0.25rem * var(--tw-space-y-reverse)) !important;
}

.space-x-1 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0 !important;
  margin-right: calc(0.25rem * var(--tw-space-x-reverse)) !important;
  margin-left: calc(0.25rem * calc(1 - var(--tw-space-x-reverse))) !important;
}

.space-x-12 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0 !important;
  margin-right: calc(3rem * var(--tw-space-x-reverse)) !important;
  margin-left: calc(3rem * calc(1 - var(--tw-space-x-reverse))) !important;
}

.space-x-2 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0 !important;
  margin-right: calc(0.5rem * var(--tw-space-x-reverse)) !important;
  margin-left: calc(0.5rem * calc(1 - var(--tw-space-x-reverse))) !important;
}

.space-x-3 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0 !important;
  margin-right: calc(0.75rem * var(--tw-space-x-reverse)) !important;
  margin-left: calc(0.75rem * calc(1 - var(--tw-space-x-reverse))) !important;
}

.space-x-4 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0 !important;
  margin-right: calc(1rem * var(--tw-space-x-reverse)) !important;
  margin-left: calc(1rem * calc(1 - var(--tw-space-x-reverse))) !important;
}

.space-x-6 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0 !important;
  margin-right: calc(1.5rem * var(--tw-space-x-reverse)) !important;
  margin-left: calc(1.5rem * calc(1 - var(--tw-space-x-reverse))) !important;
}

.space-y-10 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0 !important;
  margin-top: calc(2.5rem * calc(1 - var(--tw-space-y-reverse))) !important;
  margin-bottom: calc(2.5rem * var(--tw-space-y-reverse)) !important;
}

.space-y-2 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0 !important;
  margin-top: calc(0.5rem * calc(1 - var(--tw-space-y-reverse))) !important;
  margin-bottom: calc(0.5rem * var(--tw-space-y-reverse)) !important;
}

.space-y-4 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0 !important;
  margin-top: calc(1rem * calc(1 - var(--tw-space-y-reverse))) !important;
  margin-bottom: calc(1rem * var(--tw-space-y-reverse)) !important;
}

.divide-y > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-y-reverse: 0 !important;
  border-top-width: calc(1px * calc(1 - var(--tw-divide-y-reverse))) !important;
  border-bottom-width: calc(1px * var(--tw-divide-y-reverse)) !important;
}

.divide-gray-100 > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-opacity: 1 !important;
  border-color: rgb(243 244 246 / var(--tw-divide-opacity)) !important;
}

.overflow-hidden {
  overflow: hidden !important;
}

.rounded {
  border-radius: 0.25rem !important;
}

.rounded-2xl {
  border-radius: 1rem !important;
}

.rounded-lg {
  border-radius: 0.5rem !important;
}

.border {
  border-width: 1px !important;
}

.border-2 {
  border-width: 2px !important;
}

.border-b {
  border-bottom-width: 1px !important;
}

.border-t {
  border-top-width: 1px !important;
}

.border-solid {
  border-style: solid !important;
}

.border-none {
  border-style: none !important;
}

.border-gray-100 {
  --tw-border-opacity: 1 !important;
  border-color: rgb(243 244 246 / var(--tw-border-opacity)) !important;
}

.border-gray-400 {
  --tw-border-opacity: 1 !important;
  border-color: rgb(156 163 175 / var(--tw-border-opacity)) !important;
}

.border-red-500 {
  --tw-border-opacity: 1 !important;
  border-color: rgb(239 68 68 / var(--tw-border-opacity)) !important;
}

.bg-gray-200 {
  --tw-bg-opacity: 1 !important;
  background-color: rgb(229 231 235 / var(--tw-bg-opacity)) !important;
}

.bg-gray-300 {
  --tw-bg-opacity: 1 !important;
  background-color: rgb(209 213 219 / var(--tw-bg-opacity)) !important;
}

.bg-gray-400 {
  --tw-bg-opacity: 1 !important;
  background-color: rgb(156 163 175 / var(--tw-bg-opacity)) !important;
}

.bg-gray-50 {
  --tw-bg-opacity: 1 !important;
  background-color: rgb(249 250 251 / var(--tw-bg-opacity)) !important;
}

.bg-stone-100 {
  --tw-bg-opacity: 1 !important;
  background-color: rgb(245 245 244 / var(--tw-bg-opacity)) !important;
}

.bg-white {
  --tw-bg-opacity: 1 !important;
  background-color: rgb(255 255 255 / var(--tw-bg-opacity)) !important;
}

.object-contain {
  -o-object-fit: contain !important;
  object-fit: contain !important;
}

.p-10 {
  padding: 2.5rem !important;
}

.p-4 {
  padding: 1rem !important;
}

.px-1 {
  padding-left: 0.25rem !important;
  padding-right: 0.25rem !important;
}

.px-3 {
  padding-left: 0.75rem !important;
  padding-right: 0.75rem !important;
}

.px-4 {
  padding-left: 1rem !important;
  padding-right: 1rem !important;
}

.px-6 {
  padding-left: 1.5rem !important;
  padding-right: 1.5rem !important;
}

.py-1 {
  padding-top: 0.25rem !important;
  padding-bottom: 0.25rem !important;
}

.py-2 {
  padding-top: 0.5rem !important;
  padding-bottom: 0.5rem !important;
}

.py-4 {
  padding-top: 1rem !important;
  padding-bottom: 1rem !important;
}

.py-6 {
  padding-top: 1.5rem !important;
  padding-bottom: 1.5rem !important;
}

.pb-10 {
  padding-bottom: 2.5rem !important;
}

.pb-\[2\.5rem\] {
  padding-bottom: 2.5rem !important;
}

.pl-8 {
  padding-left: 2rem !important;
}

.pt-2 {
  padding-top: 0.5rem !important;
}

.pt-4 {
  padding-top: 1rem !important;
}

.pt-6 {
  padding-top: 1.5rem !important;
}

.pt-\[5\.75rem\] {
  padding-top: 5.75rem !important;
}

.text-left {
  text-align: left !important;
}

.text-center {
  text-align: center !important;
}

.text-right {
  text-align: right !important;
}

.font-report {
  font-family: THSarabunNew, Helvetica, Arial, sans-serif !important;
}

.font-sans {
  font-family:
    ui-sans-serif,
    system-ui,
    -apple-system,
    BlinkMacSystemFont,
    'Segoe UI',
    Roboto,
    'Helvetica Neue',
    Arial,
    'Noto Sans',
    sans-serif,
    'Apple Color Emoji',
    'Segoe UI Emoji',
    'Segoe UI Symbol',
    'Noto Color Emoji' !important;
}

.text-2xl {
  font-size: 1.5rem !important;
  line-height: 2rem !important;
}

.text-4xl {
  font-size: 2.25rem !important;
  line-height: 2.5rem !important;
}

.text-6xl {
  font-size: 3.75rem !important;
  line-height: 1 !important;
}

.text-base {
  font-size: 1rem !important;
  line-height: 1.5rem !important;
}

.text-lg {
  font-size: 1.125rem !important;
  line-height: 1.75rem !important;
}

.text-sm {
  font-size: 0.875rem !important;
  line-height: 1.25rem !important;
}

.text-xl {
  font-size: 1.25rem !important;
  line-height: 1.75rem !important;
}

.font-bold {
  font-weight: 700 !important;
}

.font-medium {
  font-weight: 500 !important;
}

.font-semibold {
  font-weight: 600 !important;
}

.uppercase {
  text-transform: uppercase !important;
}

.italic {
  font-style: italic !important;
}

.leading-6 {
  line-height: 1.5rem !important;
}

.leading-7 {
  line-height: 1.75rem !important;
}

.text-gray-500 {
  --tw-text-opacity: 1 !important;
  color: rgb(107 114 128 / var(--tw-text-opacity)) !important;
}

.text-gray-700 {
  --tw-text-opacity: 1 !important;
  color: rgb(55 65 81 / var(--tw-text-opacity)) !important;
}

.text-gray-900 {
  --tw-text-opacity: 1 !important;
  color: rgb(17 24 39 / var(--tw-text-opacity)) !important;
}

.text-red-500 {
  --tw-text-opacity: 1 !important;
  color: rgb(239 68 68 / var(--tw-text-opacity)) !important;
}

.text-stone-500 {
  --tw-text-opacity: 1 !important;
  color: rgb(120 113 108 / var(--tw-text-opacity)) !important;
}

.text-stone-700 {
  --tw-text-opacity: 1 !important;
  color: rgb(68 64 60 / var(--tw-text-opacity)) !important;
}

.shadow {
  --tw-shadow: 0 1px 3px 0 rgb(0 0 0 / 0.1), 0 1px 2px -1px rgb(0 0 0 / 0.1) !important;
  --tw-shadow-colored: 0 1px 3px 0 var(--tw-shadow-color),
    0 1px 2px -1px var(--tw-shadow-color) !important;
  -webkit-box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000),
    var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow) !important;
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000),
    var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow) !important;
}

.\!filter {
  -webkit-filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast)
    var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate)
    var(--tw-sepia) var(--tw-drop-shadow) !important;
  filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast)
    var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate)
    var(--tw-sepia) var(--tw-drop-shadow) !important;
}

.filter {
  -webkit-filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast)
    var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate)
    var(--tw-sepia) var(--tw-drop-shadow) !important;
  filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast)
    var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate)
    var(--tw-sepia) var(--tw-drop-shadow) !important;
}

.odd\:bg-white:nth-child(odd) {
  --tw-bg-opacity: 1 !important;
  background-color: rgb(255 255 255 / var(--tw-bg-opacity)) !important;
}

.even\:bg-stone-50:nth-child(even) {
  --tw-bg-opacity: 1 !important;
  background-color: rgb(250 250 249 / var(--tw-bg-opacity)) !important;
}

.hover\:bg-stone-300:hover {
  --tw-bg-opacity: 1 !important;
  background-color: rgb(214 211 209 / var(--tw-bg-opacity)) !important;
}

:is([dir='rtl'] .rtl\:text-right) {
  text-align: right !important;
}

@media print {
  .print\:hidden {
    display: none !important;
  }

  .print\:bg-transparent {
    background-color: transparent !important;
  }

  .print\:pb-0 {
    padding-bottom: 0px !important;
  }

  .print\:pt-0 {
    padding-top: 0px !important;
  }

  .print\:shadow-none {
    --tw-shadow: 0 0 #0000 !important;
    --tw-shadow-colored: 0 0 #0000 !important;
    -webkit-box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000),
      var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow) !important;
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000),
      var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow) !important;
  }
}

@media (min-width: 640px) {
  .sm\:col-span-2 {
    grid-column: span 2 / span 2 !important;
  }

  .sm\:mt-0 {
    margin-top: 0px !important;
  }

  .sm\:grid {
    display: grid !important;
  }

  .sm\:grid-cols-3 {
    grid-template-columns: repeat(3, minmax(0, 1fr)) !important;
  }

  .sm\:gap-4 {
    gap: 1rem !important;
  }

  .sm\:px-0 {
    padding-left: 0px !important;
    padding-right: 0px !important;
  }
}

@media (min-width: 768px) {
  .md\:space-y-0 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0 !important;
    margin-top: calc(0px * calc(1 - var(--tw-space-y-reverse))) !important;
    margin-bottom: calc(0px * var(--tw-space-y-reverse)) !important;
  }
}
