@font-face {
  font-family: 'Kanit';
  font-style: normal;
  font-weight: 400;
  src:
    local('Kanit'),
    local('Kanit-Regular'),
    url(./styles/kanit.woff2) format('woff2');
  unicode-range: U+0E01-0E5B, U+200B-200D, U+25CC;
}
/* latin */
@font-face {
  font-family: 'Kanit';
  font-style: normal;
  font-weight: 400;
  src:
    local('Kanit'),
    local('Kanit-Regular'),
    url(./styles/kanit-latin.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02C6, U+02DA, U+02DC,
    U+2000-206F, U+2074, U+20AC, U+2212, U+2215, U+E0FF, U+EFFD, U+F000;
}

@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  src:
    local('Roboto'),
    local('Roboto-Regular'),
    url(./styles/Roboto-Regular.ttf) format('truetype');
  unicode-range: U+0E01-0E5B, U+200B-200D, U+25CC;
}
/* latin */
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  src:
    local('Roboto'),
    local('Roboto-Regular'),
    url(./styles/Roboto-Regular.ttf) format('truetype');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02C6, U+02DA, U+02DC,
    U+2000-206F, U+2074, U+20AC, U+2212, U+2215, U+E0FF, U+EFFD, U+F000;
}

@font-face {
  font-family: 'THSarabunNew';
  font-style: normal;
  font-weight: 400;
  src:
    local('THSarabunNew'),
    local('THSarabunNew-Regular'),
    url('./styles/THSarabunNew.ttf') format('truetype');
}

@font-face {
  font-family: 'THSarabunNew';
  font-style: normal;
  font-weight: 600;
  src:
    local('THSarabunNew'),
    local('THSarabunNew-Bold'),
    url('./styles/THSarabunNew Bold.ttf') format('truetype');
}

@font-face {
  font-family: 'THSarabunNew';
  font-style: italic;
  font-weight: 400;
  src:
    local('THSarabunNew'),
    local('THSarabunNew-Italic'),
    url('./styles/THSarabunNew Italic.ttf') format('truetype');
}

@font-face {
  font-family: 'THSarabunNew';
  font-style: italic;
  font-weight: 600;
  src:
    local('THSarabunNew'),
    local('THSarabunNew-BoldItalic'),
    url('./styles/THSarabunNew BoldItalic.ttf') format('truetype');
}

body {
  font-family: Roboto, THSarabunNew;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  -webkit-print-color-adjust: exact !important;
  print-color-adjust: exact !important;
}

.app-bar {
  border-radius: 0px;
}

.app-bar .container {
  padding-left: 10px;
  padding-right: 20px;
  width: auto;
}

.app-bar-brand {
  margin-left: -20px;
  padding-left: 20px;
  padding-right: 20px;
}

/*
   * Base structure
   */

/* Move down content because we have a fixed navbar that is 50px tall */

/*
   * Global add-ons
   */

.sub-header {
  padding-bottom: 10px;
  border-bottom: 1px solid #eee;
}

.navbar-nav > li.nav-menu-toggle > a {
  padding-top: 14px;
  padding-bottom: 14px;
}

.navbar-nav .menu-toggle {
  display: block !important;
}

/*
   * Sidebar
   */

.sidebar {
  position: fixed;
  top: 50px;
  bottom: 0;
  left: 0;
  z-index: 50;
  display: block;
  /* padding: 20px; */
  overflow-x: hidden;
  overflow-y: auto; /* Scrollable contents if viewport is shorter than content. */
  background-color: #f8f8f8;
  border-right: 1px solid #eee;
  width: 240px;

  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;

  overflow-x: hidden;
  transition: 0.5s;
}
.sidebar-title {
  transition:
    opacity 0.5s,
    font-size 0.2s,
    margin 0.6s;
  margin-top: 8px;
  margin-bottom: 5px;
}
.sidebar-title-hide {
  opacity: 0;
  /* visibility: hidden; 
    font-size: 0px; */
  margin-top: 8px;
  margin-bottom: 5px;
  transition:
    opacity 0.5s,
    font-size 0.2s,
    margin 0.6s;
}

/* Sidebar navigation */
.nav-sidebar > li > a {
  padding-right: 20px;
  padding-left: 20px;
}
.nav-sidebar > .active > a,
.nav-sidebar > .active > a:hover,
.nav-sidebar > .active > a:focus {
  color: #fff;
  background-color: #a46c35;
}
.divider {
  height: 1px;
  margin: 2px 0;
  overflow: hidden;
  background-color: #e5e5e5;
}

/*
   * Main content
   */

.main {
  transition: 0.5s;
  margin-left: 0px;
  padding: 20px;
  min-width: 600px;
}
@media (min-width: 768px) {
  .main {
    padding-right: 40px;
    padding-left: 40px;
  }
}
.main .page-header {
  margin-top: 0;
}

/*
   * Placeholder dashboard ideas
   */

.placeholders {
  margin-bottom: 30px;
  text-align: center;
}
.placeholders h4 {
  margin-bottom: 0;
}
.placeholder {
  margin-bottom: 20px;
}
.placeholder img {
  display: inline-block;
  border-radius: 50%;
}

.nav > li > a.menu-link {
  display: flex;
  justify-content: left;
  align-items: center;
}

.nav > li > a.menu-link > .menu-icon {
  padding-right: 27px;
}

.nav > li > a.menu-link > .menu-text {
  transition:
    opacity 0.5s,
    font-size 0.2s,
    margin 0.6s;
  white-space: pre;
}

.nav > li > a.menu-link > .menu-text-hide {
  opacity: 0;
  white-space: pre;
  transition:
    opacity 0.5s,
    font-size 0.2s,
    margin 0.6s;
}

.navbar-collapse.collapse {
  display: block !important;
}

.navbar-nav > li,
.navbar-nav {
  float: left !important;
}

.navbar-nav.navbar-right:last-child {
  margin-right: -15px !important;
}

.navbar-right {
  float: right !important;
}
.navbar-nav > li > a {
  padding-top: 15px;
  padding-bottom: 15px;
}
.navbar-header {
  float: left;
}
.navbar-nav {
  margin: 0;
}
.navbar-collapse {
  border-top-width: 0px;
}

.navbar-nav > li > a {
  padding-top: 15px;
  padding-bottom: 15px;
}
.dropdown-menu {
  z-index: 51;
}
@media (max-width: 767px) {
  .navbar-nav .open .dropdown-menu {
    position: absolute;
    top: 100%;
    left: 0;
    z-index: 1000;
    float: left;
    min-width: 160px;
    padding: 5px 0;
    margin-top: 0;
    list-style: none;
    font-size: 14px;
    text-align: left;
    background-color: #fff;
    border: 1px solid #ccc;
    border: 1px solid rgba(0, 0, 0, 0.15);
    border-radius: 4px;
    -webkit-box-shadow: 0 6px 12px rgba(0, 0, 0, 0.175);
    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.175);
    background-clip: padding-box;
    display: block;
    right: 0;
    left: auto;
  }

  .navbar-inverse .navbar-nav .open .dropdown-menu > li > a {
    font-weight: normal;
    line-height: 1.42857;
    color: #333333;
    white-space: nowrap;
  }

  .navbar-inverse .navbar-nav .open .dropdown-menu > li > a:hover,
  .navbar-inverse .navbar-nav .open .dropdown-menu > li > a:focus {
    color: #262626;
    background-color: #f5f5f5;
    text-decoration: none;
  }
  .navbar-nav .open .dropdown-menu > li > a,
  .navbar-nav .open .dropdown-menu .dropdown-header {
    padding: 3px 20px;
  }
  .navbar-inverse .navbar-nav .open .dropdown-menu .divider {
    background-color: #e5e5e5;
  }
}

.Select {
  height: 36px;
}

.Select-input {
  height: 34px;
}

.btn-file {
  position: relative;
  overflow: hidden;
}
.btn-file input[type='file'] {
  position: absolute;
  top: 0;
  right: 0;
  min-width: 100%;
  min-height: 100%;
  font-size: 100px;
  text-align: right;
  filter: alpha(opacity=0);
  opacity: 0;
  outline: none;
  background: white;
  cursor: inherit;
  display: block;
}

.modal-reservation {
  width: 70%;
}

hr {
  -moz-border-bottom-colors: none;
  -moz-border-image: none;
  -moz-border-left-colors: none;
  -moz-border-right-colors: none;
  -moz-border-top-colors: none;
  border-color: #eeeeee -moz-use-text-color #ffffff;
  border-style: solid none;
  border-width: 1px 0;
  margin: 18px 0;
}

.form-control,
.form-control-static {
  color: black;
}

.staff-table-row {
  height: 92px;
}

.airline-table-row {
  height: 70px;
}

.modal-reservation .modal-body {
  min-height: 500px;
}
.modal-header {
  border-top-right-radius: 6px;
  border-top-left-radius: 6px;
}

.btn-assigned {
  color: #b1b1b1;
  background-color: #fff;
  border-color: #ccc;
}

.btn-primary[disabled] {
  color: #b1b1b1;
  background-color: #fff;
  border-color: #ccc;
}
.btn-primary[disabled]:hover {
  color: #999999;
  background-color: #fff;
  border-color: #ccc;
}

.page-label .form-control {
  text-align: center;
}

.page-label .input-group-addon {
  padding: 6px 15px;
}

.pager-btn {
  margin-top: 27px;
}
.canceled-form {
  opacity: 0.5;
}

.soon {
  color: #b9b9b9 !important;
}

.text-strong {
  font-weight: bold;
}
