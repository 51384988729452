.menu-sidebar.modal.fade .modal-dialog {
  transition: none;
}
.menu-sidebar.modal.fade {
  transition:
    transform 0.3s ease-out,
    opacity 0.15s linear;
}
.menu-sidebar.modal.fade:not(.in).left {
  transform: translate3d(-100%, 0, 0);
}
.menu-sidebar.modal.fade:not(.in).right {
  transform: translate3d(100%, 0, 0);
}
.menu-sidebar.modal {
  max-width: 100%;
  background-color: #f8f8f8;
}
.menu-sidebar.modal.left {
  right: auto;
}
.menu-sidebar.modal.right {
  left: auto;
}
.menu-sidebar .modal-header {
  border: 0;
  background-color: #f8f8f8;
  color: #8b5c2d;
}
.menu-sidebar.modal .modal-dialog {
  margin: 0;
  width: auto;
}
.menu-sidebar.modal .modal-dialog .modal-content {
  background-color: #f8f8f8;
  box-shadow: none;
  overflow-x: hidden;
  border: 0;
}
.menu-sidebar.modal .modal-dialog .modal-content .modal-body {
}
.menu-sidebar.modal.fade:not(.in).left .modal-dialog {
  transform: translate3d(-100%, 0, 0);
}
.menu-sidebar.modal.fade:not(.in).right .modal-dialog {
  transform: translate3d(100%, 0, 0);
}
.menu-sidebar .nav {
  width: 320px;
}
.divider {
  height: 1px;
  margin: 2px 0;
  overflow: hidden;
  background-color: #e5e5e5;
}
.menu-sidebar.modal .modal-dialog .modal-content .modal-body ul.nav li a,
.menu-sidebar.modal .modal-dialog .modal-content .modal-body ul.nav li a:hover,
.menu-sidebar.modal .modal-dialog .modal-content .modal-body ul.nav li a:focus {
  color: #a46c35;
}
.menu-sidebar.modal .modal-dialog .modal-content .modal-body ul.nav li.active a,
.menu-sidebar.modal
  .modal-dialog
  .modal-content
  .modal-body
  ul.nav
  li.active
  a:hover,
.menu-sidebar.modal
  .modal-dialog
  .modal-content
  .modal-body
  ul.nav
  li.active
  a:focus {
  color: #fff;
  background-color: #a46c35;
}
.nav-sidebar > li > a {
  padding-right: 20px;
  padding-left: 20px;
}
.nav-sidebar > .active > a,
.nav-sidebar > .active > a:hover,
.nav-sidebar > .active > a:focus {
  color: #fff;
  background-color: #a46c35;
}
.divider {
  height: 1px;
  margin: 2px 0;
  overflow: hidden;
  background-color: #e5e5e5;
}
